import ApiService from "@/core/services/api.service";
import { responseError } from "@/core/mixin/shared";
import querystring from "querystring";

export const BASE_URL = "api/recent_tag";
export const MODULE_NAME = "HASHTAG";
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;
export const ITEM = `ITEM`;
export const ITEMS = `ITEMS`;

export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const SET_ITEMS = `M/ITEMS`;
export const SET_ITEM = `M/CURRENT_ITEM`;
export const RESET_VALUES = `M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `GET_ITEMS`;
export const GET_ITEM = `GET_ITEM`;
export const UPDATE_ITEM = `UPDATE_ITEM`;
export const DELETE_ITEM = `DELETE_ITEM`;
export const CREATE_ITEM = `CREATE_ITEM`;

const state = {
  items: [],
  item: {},
  loading: false,
  error: null,
  success: null,
};

const getters = {
  [LOADING]: (state) => {
    return state.loading;
  },
  [ERROR]: (state) => {
    return state.error;
  },
  [SUCCESS]: (state) => {
    return state.success;
  },
  [ITEMS]: (state) => {
    return state.items;
  },
  [ITEM]: (state) => {
    return state.item;
  },
};

const mutations = {
  [SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [SET_ERROR]: (state, payload) => {
    state.error = payload ? responseError(payload) : null;
  },
  [SET_ITEMS]: (state, payload) => {
    state.items = payload;
  },
  [SET_SUCCESS]: (state, payload) => {
    state.success = payload;
  },
  [SET_ITEM]: (state, payload) => {
    state.item = payload;
  },
  [RESET_VALUES]: (state) => {
    state.success = null;
    state.error = null;
    state.loading = false;
    state.item = null;
  },
};

const actions = {
  [GET_ITEMS]: (context, payload) => {
    let url = payload.url || BASE_URL;
    let filters = payload.filters;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.get(`${url}?` + querystring.stringify(filters))
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_ITEMS, response);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [GET_ITEM]: (context, payload) => {
    let url = payload.url || BASE_URL;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.get(url)
      .then((response) => {
        context.commit(SET_ITEM, response.data);
        context.commit(SET_LOADING, false);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [DELETE_ITEM]: (context, payload) => {
    let url = payload.url || BASE_URL;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.delete(url)
      .then(() => {
        context.commit(SET_ITEM, {});
        context.commit(SET_LOADING, false);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [UPDATE_ITEM]: (context, payload) => {
    let url = payload.url || BASE_URL;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    ApiService.post(url, contents)
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_ITEM, response.data);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [CREATE_ITEM]: (context, payload) => {
    let url = payload.url;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    ApiService.post(url, contents)
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_ITEM, response.data);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
